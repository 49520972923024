.portfolio-detail-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    
    .banner {
        width: 100vw;
        height:30vw;
        box-shadow: 0px 15px 20px rgba(0,0,0, 0.3);
        margin-bottom: 40px;

        @media screen and (max-width:900px) {
           height:40vh; 
        }
    }

    h2 {
        color:$grey;
        @media screen and (max-width:768px) {
           font-size: 1em;
        }
    }

    .site-link {
        margin: 15px;
        a {
            color:white;
            text-decoration: none;
            font-weight: 600;
            padding:12px;
            font-size: 1.2em;
            background-color: $grey;
            transition: 0.2s ease-in-out;

            &:hover {
                background-color: $fuschia;
            }
        }
    }   

    .description {
        display:flex;
        flex-direction: column;
        justify-content:center;
        align-items: center;
        width: 40%;

        @media screen and (max-width:768px) {
            width: 70%;   
        } 
    }   
}