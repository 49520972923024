.blog-container {
    display: flex;
    flex-direction: column;
    margin: 0px 200px;
    align-items: center;

    @media screen and (max-width: 1000px) {
        margin: 0px 20px;
    }

    .admin-blog-wrapper {
        display:flex;
        justify-content: space-between;
        align-items: center;

        a {
            margin-left: 15px;
            cursor: pointer;
        }
    }
    .content-container {
        display: flex;
        flex-direction: column;
        width: 800px;

        @media screen and (max-width: 900px) {
            width: 80vw;
            margin:0px 10px;
        }

        
        a {
            text-decoration: none;
            color: $grey;
            transition: 0.5s ease-in-out;

            &:hover {
                color: $fuschia;
            }

            h1 {
                font-size: 1.2em;
            }
        }  
    
        .featured-image-wrapper {
            img {
                margin: 15px 0px;
                width: 100%;
                height: 350px;
                border-radius: 5px;
                object-fit: contain;

            }
        }
    }


 
    .new-blog-link {
        position: fixed;
        right: 0;
        bottom: 10px;

        a {
            font-size: 3em;
            cursor: pointer;
            color: $fuschia;
            margin-right: 30px;
            margin-bottom: 40px;
            transition: 0.5s ease-in-out;

            &:hover {
                color: $dark-pink;
            }
        }
    }
    
}