.auth-page-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: calc(100vh - 84px);

    @media screen and (max-width: 1000px ) {
        grid-template-columns: 1fr;
        
    }
    .left-column {
        background-size: cover;
        background-position-x: -150px;
        background-position-y: -10px;
    }

    .right-column {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $offwhite;
        font-size: 0.7em;
        color: $grey;

        .auth-form-wrapper {
            @include input-element();
            @include base-grid();
            gap: 21px;
            padding: 42px;

            .form-group {
                display:flex;
                align-items: center;

                svg {
                    font-size: 2em;
                    color: $fuschia;
                    margin-right: 15px;
                }
            }

            .btn {
                font-size: 1.5em;
            }
        }

    }
}