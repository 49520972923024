.contact-page-wrapper {
    display:grid;
    grid-template-columns: 1fr 1fr;
    gap:0;
    height: calc(100vh - 84px);

    .page-left {
        display:flex;
        justify-content:center;   
    }

    .page-right{
        padding:50px;
        display:flex;
        flex-direction: column;
        align-items: center;

        @media screen and (max-width: 768px) {
            padding:10px;
        }

        a {
              text-decoration: none;
              color: $grey;
        }
      
        
        h1 {
            font-weight: 600;
            color: $grey;
            grid-area: 1fr 1fr;
        }

        .contact-area {
            display:grid;
            grid-template-columns: 1fr 1fr;
            gap: 20px;
            margin-top: 50px;

            .handle {
                display:flex;
                align-items:center;
                .icon {
                    width: 32px;
                    height: 32px;
                    padding-right: 5px;

                    img {
                        width:100%;
                        height:100%;
                    }
                }          
            }
            

            a {
                text-decoration: none;
                color: $grey;

                &:hover {
                    color: $fuschia;
                    
                }
            }
            
            
        }

        .attribution {
            position:fixed;
            bottom: 0px;
            grid-area: 1fr 1fr;
        }

    
    }

    @media screen and (max-width: 900px) {
        margin: 50px;
        grid-template-columns: 1fr;
    }

}