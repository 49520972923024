.one-column {
    @include base-grid;
    grid-template-columns:1fr;
   
}

.two-column {
     @include base-grid;
    grid-template-columns: 1fr 1fr;

}

.three-column {
     @include base-grid;
    grid-template-columns:1fr 1fr 1fr;
    
}