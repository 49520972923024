.homepage-wrapper {
    display: grid;
    grid-template-columns: 1fr;

    .filter-links {
        display: flex;
        justify-content: center;

        .btn {
            width: 100%
        }
    }
}
