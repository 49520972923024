.portfolio-items-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    @media screen and (max-width: 1000px) {
         grid-template-columns: 1fr 1fr;
    }

    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
    }

    .portfolio-item-wrapper {
        position: relative;

        .portfolio-img-background {
            background-size: cover;
            background-repeat: no-repeat;
            width: 100%;
            height: 300px;
        }

        .img-text-wrapper {
            position:absolute;
            top: 0;
            display: flex;
            flex-direction:column;
            justify-content: center;
            align-items: center;
            height: 100%;
            text-align:center;
            padding-left:100px;
            padding-right:100px;

            .subtitle {
                transition: 1s ease-in-out;
                color:transparent;

            }

        }
         .img-text-wrapper:hover .subtitle {
             color: $fuschia;
             font-weight: 300;
             font-size: 0.9em;
         }
        
         .logo-wrapper img {
             width: 50px;
             margin-bottom: 20px;

         }
    }

    .image-blur {
        transition: 1s;
        filter: brightness(10%);
    }

}