.pm-wrapper {
    display: grid;
    grid-template-columns: 3fr 1fr;

    .form-section {
        background-color: $offwhite;
        border: black 5 px solid;

    }

    .portfolio-items-section {
        background-color: $charcoal;
        
    }
}