.nav-wrapper {
    display: flex;
    justify-content: space-between;
    padding:30px;
    font-size: 1.4em;

    @media screen and (max-width: 1000px) {
        flex-direction:column-reverse; 
        margin: 10px 0px;
        align-items: center;  
        font-size: 1em;        
    }

    .right-side {
        display:flex;

        h4 {
            margin: 5px;
            color: $grey;
            white-space: nowrap;
             @media screen and (max-width: 1000px) {
                 color: $fuschia;
                 margin: 10px 0px;
             }
        }

        a {
            align-self: center;
            margin-left: 15px;
            cursor:pointer;
            color: $fuschia;
            transition: 0.5s ease-in-out;

            &:hover {
                color: $dark-pink;
            }
        }
    }


    .left-side {
        display: flex;
    }
}

.nav-link-wrapper {
    height: 22px;
    border-bottom: 1px solid transparent;
    transition: 0.5s ease-in-out;
    
    margin-right: 20px;
    text-transform: uppercase;
    font-size: 1rem;


        a {
            color: $grey;
            text-decoration: none;
            
        &:hover {
            color: black;    
        }

    }

    &:hover {
       color: black; 
       border-bottom: 1px solid black;
    }

    .nav-link-active {
        border-bottom: 1 px solid black;
        color: black;     
    }
}