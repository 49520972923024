 .about-page-wrapper {
    display:grid;
    grid-template-columns: 1fr 1fr;
    gap:0;
    height: calc(100vh - 84px);

    @media screen and (max-width: 900px) {
        margin:10px 50px;
        grid-template-columns: 1fr;
    }

    .page-left {
        display:flex;
        justify-content:center;   

        @media screen and (max-width: 900px) {
            height:65vh;
        }     
    }

    .page-right{
        padding:50px;
        display:flex;
        justify-content:center;
        flex-direction:column;

        @media screen and (max-width: 900px) {
            margin: 20px;
            padding: 5px;
        }

        h1 {
            font-weight: 600;
            color: $grey;
        }
    
    }

}