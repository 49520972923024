body {
    margin: 0px;
    font-family: 'Times New Roman', sans-serif;
}

h1,h2,h3,h4, h5 {
 font-family: 'Krona One', sans-serif;
}

.container {
    display:grid;
    grid-template-columns: 1fr;
}