@mixin base-btn {
    cursor: pointer;
    height: 42px;
    font-size: 1em;
    font-weight: 500;
    border: 1px solid transparent;
    transition: all 0.5s ease-in-out;
    background-color: $grey;
    color:white;

    &:active, &:focus {
        outline: none;
    }

    &:hover {
        background-color: $fuschia;
    }
}

@mixin input-element {
    input {
        font-family: "Titillium Web", sans-serif;
        color: $charcoal;
        padding: 5px 0px;
        width: 100%;
        height: 30px;
        border-top: 0px;
        border-right: 0px;
        border-left: 0px;
        border-bottom: 1px solid $fuschia;
        background-color: transparent;
        transition: 0.5s all ease-in-out;

        &:focus {
            outline: none;
            border-bottom: 2px solid $dark-pink;
        }


    }
}

@mixin base-grid {
    display:grid;
    gap: 21px;
}
