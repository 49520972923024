
.DraftEditor-root{
    border:1px solid #f1f1f1;
    padding:20px;
    min-height: 100px;
    border-radius: 7px;
} 


.rdw-emoji-modal, .rdw-embedded-modal{
    left: -170px;
}

.rdw-image-modal{
    width: 270px;
    padding: 20px;
}