.portfolio-sidebar-list-wrapper {
    padding: 20px;
    .portfolio-item-thumb {
        padding: 21px;

        .portfolio-thumb-img img {
            width:100%;
        }

        h1 {
            font-size: 1.2em;
        }
        .text-content {
            display:flex;
            justify-content: space-between;
        }
        .title {
            color: $offwhite;
        }
        .actions {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 20px;

            .action-icon {
                cursor:pointer;
                color:$offwhite;
                font-size: 1.5em;
                transition: 0.5s ease-in-out;

                &:hover {
                    color:$warning;
                }
            }
        }
    }
}